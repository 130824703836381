<template>
  <div>
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form
        ref="form"
        :model="queryParams"
        @keyup.enter.native="onSearch"
        inline
      >
        <el-form-item label="商户名称">
          <el-input v-model="queryParams.customerName"></el-input>
        </el-form-item>
        <el-form-item label="商户手机号">
          <el-input v-model="queryParams.customerMobile"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="onReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="action-area">
      <el-button type="primary" @click="onAppend">
        <i class="el-icon-plus"></i> 添加商户</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :data="tableData"
      row-key="CustomerID"
      border
      style="width: 100%"
      lazy
      :load="load"
      size="middle"
    >
      <el-table-column
        align="center"
        prop="CustomerName"
        label="商户名称"
        width="180"
        fixed
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="CustomerNames"
        label="商户拥有人姓名"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="CustomerMobile"
        label="商户手机号"
        width="180"
      >
      </el-table-column>
      <el-table-column align="center" label="微信分账状态" width="200">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.addStatus === 1">已添加</el-tag>
          <el-tag
            type="info"
            v-else-if="
              scope.row.addStatus === 0 && !scope.row.profitsharingRate
            "
            >未开通</el-tag
          >
          <el-tag
            type="danger"
            v-else-if="scope.row.addStatus === 0 && scope.row.profitsharingRate"
            >待添加</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="CustomerAliPayID"
        label="支付宝账号"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="CustomerWeChatID"
        label="微信商户号"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="pullStatus"
        label="迁移状态"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.pullStatus === 1"
            >已迁移</el-tag
          >
          <el-tag v-else>未迁移</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="6期" width="160">
        <template v-if="scope.row.staging6" slot-scope="scope"
          >商贴：{{ scope.row.proportion6 || '0' }} 客贴：{{
            scope.row.client6 || '0'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="12期" width="160">
        <template v-if="scope.row.staging12" slot-scope="scope"
          >商贴：{{ scope.row.proportion12 || '0' }} 客贴：{{
            scope.row.client12 || '0'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" width="160">
        <template v-if="scope.row.staging12" slot-scope="scope">
          {{ scope.row.CreateDateTime | formateDate('yyyy-MM-dd hh:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.AuthoStatus !== '0'"
            class="item"
            effect="dark"
            content="授权"
            placement="top"
          >
            <Icon
              iconClass="auth"
              className="svg-icon"
              @click="onAuth(scope.row)"
            ></Icon>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="子商户"
            placement="top"
            v-if="scope.row.number === '1'"
          >
            <Icon
              iconClass="child-merchant"
              className="svg-icon"
              @click="onShowChild(scope.row)"
            ></Icon>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.pullStatus === 0"
            class="item"
            effect="dark"
            content="编辑"
            placement="top"
          >
            <i @click="onEdit(scope.row)" class="icon el-icon-edit"></i>
          </el-tooltip>
          <el-dropdown>
            <span class="el-dropdown-link">
              <Icon iconClass="qrCode"></Icon>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'polymerization', '聚合码')
                "
                >聚合码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(scope.row, 'polymerization', '聚合码')
                "
                >聚合码(无边框)</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="onShowQRCode(scope.row, 'wechart', '微信码')"
                >微信码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(scope.row, 'wechart', '微信码')
                "
                >微信码(无边框)</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'hbpay', '花呗分期二维码')
                "
                >花呗码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(scope.row, 'hbpay', '花呗分期二维码')
                "
                >花呗码(无边框)</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'aliFullPay', '支付宝码')
                "
                >支付宝码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(scope.row, 'aliFullPay', '支付宝码')
                "
                >支付宝码(无边框)</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'alipay', '支付宝+花呗码')
                "
                >支付宝+花呗码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(scope.row, 'alipay', '支付宝+花呗码')
                "
                >支付宝+花呗码(无边框)</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'wechartAli', '支付宝+微信码')
                "
                >支付宝+微信码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowFramelessQrCode(
                    scope.row,
                    'wechartAli',
                    '支付宝+微信码'
                  )
                "
                >支付宝+微信码(无边框)</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button type="text" size="small" @click="onShowDetail(scope.row)"
            >更多</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <img :src="images" />
    <div class="pagination">
      <el-pagination
        background
        layout="jumper, prev, pager, next, sizes, total"
        :total="pagination.total"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        :current-page="queryParams.page"
      >
      </el-pagination>
    </div>
    <QRCode ref="qrCodeRef"></QRCode>
    <MerchantEditModule @ok="loadData" ref="editRef"></MerchantEditModule>
    <MerchantAddModule @ok="loadData(1)" ref="apendRef"></MerchantAddModule>
    <ChildMerchant ref="childMerchantRef"></ChildMerchant>
    <ProviewImg ref="proviewRef"></ProviewImg>
  </div>
</template>

<script>
import { getMerchantList, getQrcodeImg, payBaseUrl, getAuthQrCode } from '@/api/api'
import QRCode from '@/components/qrCode'
import MerchantEditModule from './modules/MerchantEditModule.vue'
import MerchantAddModule from './modules/MerchantAddModule.vue'
import ChildMerchant from './modules/ChildMerchant.vue'
import ProviewImg from '@/components/ProviewImg.vue'
import {
  getToken,
} from '@/utils/auth'
const RATE_6 = 4.5
const RATE_12 = 7.5
export default {
  methods: {
    onShowChild (record) {
      this.$refs.childMerchantRef.show(record)
    },
    load () {

    },
    onAppend () {
      this.$refs.apendRef.show()
    },
    onPageChange (e) {
      this.queryParams.page = e
      this.loadData()
    },
    onPageSizeChange (e) {
      this.queryParams.size = e
      this.loadData()
    },
    onSearch () {
      this.queryParams.page = 1
      this.loadData()
    },
    onEdit (record) {
      this.$refs.editRef.show(record.CustomerID)
    },
    onReset () {
      Object.keys(this.queryParams).forEach(key => {
        if (key !== 'page' && key !== 'size') {
          this.queryParams[key] = ''
        }
      })
      this.dateTime = ''
      this.queryParams.page = 1
      this.loadData()
    },
    onShowFramelessQrCode (e, mode, title) {
      this.$refs.qrCodeRef.show({
        title: title,
        content: payBaseUrl + '/index.html?qrCodeID=' + e.CustomerID + '&mode=' + mode
      })
    },
    onShowQRCode (e, mode, title) {
      this.loading = true
      getQrcodeImg({ customerId: e.CustomerID, mode: mode }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.$refs.proviewRef.show({
            title,
            src: res.result
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 授权
     */
    onAuth (e) {
      this.loading = true
      getAuthQrCode({ customerId: e.CustomerID }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.$refs.qrCodeRef.show({
            title: '授权',
            content: res.result
          })
        }
      })
      // const url = 'http://b.shjunyi.vip/jy/bms/merchant/oauth'
      // const redirectUrl = url + '?merchantNo=' + e.CustomerID
      // this.$refs.qrCodeRef.show({
      //   title: '授权',
      //   content: 'https://openauth.alipay.com/oauth2/appToAppAuth.htm?app_id=2019101868431928&redirect_uri=' + encodeURIComponent(redirectUrl)
      // })
    },
    loadData (page) {
      if (page) {
        this.queryParams.page = page
      }
      this.loading = true
      getMerchantList(this.queryParams).then(res => {
        this.loading = false
        if (res.code === 0) {
          res.data.forEach(item => {
            this.splitHbfqNumBearCost(item)
          })
          this.tableData = res.data
          this.pagination = {
            total: parseInt(res.total),
            size: Math.floor(parseInt(res.total) / this.queryParams.size)
          }
        } else {
          this.tableData = []
        }
      })
    },
    splitHbfqNumBearCost (item) {
      // item.hasChildren = true
      item.children = []
      if ('hbfqNumBearCost' in item) {
        if (!item.hbfqNumBearCost) {
          item.businessPost6 = '100'
          item.proportion6 = RATE_6
          item.client6 = 0
          item.businessPost12 = '100'
          item.proportion12 = RATE_12
          item.client12 = 0
          return
        }
        const stagings = item.hbfqNumBearCost.split(',')
        const length = stagings.length
        if (length === 1) {
          const source = stagings[0].split('-')
          source[2] = parseFloat(source[2]) ? parseFloat(source[2]) : 0
          if (source[0] === '6') {
            item.businessPost6 = source[1]
            item.proportion6 = source[2]
            item.client6 = (RATE_6 - source[2]).toFixed(2)
            item.staging6 = true

            item.businessPost12 = '100'
            item.proportion12 = 7.5
            item.client12 = 0
          } else {
            item.businessPost12 = source[1]
            item.proportion12 = source[2]
            item.client12 = (RATE_12 - source[2]).toFixed(2)
            item.staging12 = true

            item.businessPost6 = '100'
            item.proportion6 = 4.5
            item.client6 = 0
          }
        }
        if (length === 2) {
          if (stagings[0] === '') {
            item.businessPost6 = '100'
            item.proportion6 = 4.5
            item.client6 = 0
          } else {
            const source0 = stagings[0].split('-')
            source0[2] = parseFloat(source0[2]) ? parseFloat(source0[2]) : 0
            if (source0[0] === '6') {
              item.businessPost6 = source0[1]
              item.proportion6 = source0[2]
              item.client6 = (RATE_6 - source0[2]).toFixed(2)
              item.staging6 = true
            }
          }
          const source1 = stagings[1].split('-')
          source1[2] = parseFloat(source1[2]) ? parseFloat(source1[2]) : 0

          if (source1[0] === '12') {
            item.businessPost12 = source1[1]
            item.proportion12 = source1[2]
            item.client12 = (RATE_12 - source1[2]).toFixed(2)
            item.staging12 = true
          }
        }
      } else {
        item.businessPost6 = '100'
        item.proportion6 = RATE_6
        item.client6 = 0
        item.staging6 = true
        item.businessPost12 = '100'
        item.proportion12 = RATE_12
        item.client12 = 0
        item.staging12 = true
      }
    },
  },
  mounted () {
    this.loadData()
  },
  components: {
    QRCode,
    MerchantEditModule,
    MerchantAddModule,
    ChildMerchant,
    ProviewImg
  },
  data () {
    return {
      pagination: {},
      images: '',
      queryParams: {
        userName: getToken(),
        customerName: '',
        customerMobile: '',
        page: 1,
        size: 10,
        // isAssign: 0
      },
      loading: false,
      tableData: []
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/search.scss';

.pagination {
  margin-top: 20px;
  padding-bottom: 30px;
  float: right;
}
.icon,
.svg-icon,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.icon,
.svg-icon {
  margin-right: 6px;
  vertical-align: middle;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.action-area {
  padding-bottom: 20px;
}
</style>
