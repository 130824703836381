<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose"
    size="60%"
    class="drawer"
  >
    <el-form
      ref="formRef"
      :model="model"
      :rules="formRules"
      label-width="140px"
      v-loading="loading"
      inline
    >
      <el-form-item label="所属商户">
        <el-select
          v-model="model.parentId"
          filterable
          remote
          :remote-method="onMerchantSearch"
          v-loadmore="onMerchantScroll"
        >
          <el-option label="总商户" value=""></el-option>
          <el-option
            v-for="(item, key) in merchantList"
            :label="item.CustomerName"
            :value="item.CustomerID"
            :key="key"
          >
            <span style="float: left">{{ item.CustomerName }}</span>
            <span
              style="
                float: right;
                color: #8492a6;
                font-size: 13px;
                padding-left: 10px;
              "
              >{{ item.CustomerWeChatID }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号">
        <el-select v-model="model.userName">
          <el-option
            v-for="(item, key) in userList"
            :label="item"
            :value="item"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="model.parentId ? '子' + '商户名称' : '商户名称'"
        prop="customerName"
      >
        <el-input v-model="model.customerName"></el-input>
      </el-form-item>
      <el-form-item
        :label="model.parentId ? '子' + '商户拥有人名称' : '商户拥有人名称'"
        prop="customerNames"
      >
        <el-input v-model="model.customerNames"></el-input>
      </el-form-item>
      <el-form-item
        :label="model.parentId ? '子' + '商户编号' : '商户编号'"
        prop="customerMobile"
      >
        <el-input v-model="model.customerMobile"></el-input>
      </el-form-item>

      <el-form-item
        v-if="!model.parentId"
        label="支付宝账号"
        prop="customerAliPayID"
      >
        <el-input v-model="model.customerAliPayID"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId"
        label="微信商户号"
        prop="customerWeChatID"
      >
        <el-input v-model="model.customerWeChatID"></el-input>
      </el-form-item>
      <el-form-item
        :label="model.parentId ? '子' + '商户邮箱' : '商户邮箱'"
        prop="mailAccount"
      >
        <el-input v-model="model.mailAccount"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId"
        label="微信分账比例"
        prop="profitsharingRate"
      >
        <el-select
          :disabled="!model.customerWeChatID"
          v-model="model.profitsharingRate"
        >
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        v-if="!model.parentId"
        label="微信费率"
        prop="profitsharingRate"
      >
        <el-select
          v-model="model.wechatRate"
          :disabled="!!model.customerWeChatID"
        >
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId"
        label="支付宝费率"
        prop="profitsharingRate"
      >
        <el-select v-model="model.alipayRate">
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <template v-if="!model.parentId">
        <el-form-item label="付款时填写手机号">
          <el-switch v-model="model.isMobile"> </el-switch>
        </el-form-item>
        <el-form-item label="付款时填写姓名">
          <el-switch v-model="model.isName"> </el-switch>
        </el-form-item>
        <el-form-item label="花呗分期支付">
          <el-switch v-model="model.isStages"> </el-switch>
        </el-form-item>
        <el-form-item label="支付宝支付">
          <el-switch v-model="model.isAlipay"> </el-switch>
        </el-form-item>
        <template v-if="model.isStages">
          <el-form-item label="6期">
            <el-switch v-model="model.staging6"> </el-switch>
          </el-form-item>
          <template v-if="model.staging6">
            <el-form-item label="6期商贴费率">
              <el-input-number
                v-model="model.proportion6"
                :precision="2"
                :step="0.1"
                :max="4.5"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="6期客贴费率">
              <el-input v-model="client6" disabled></el-input>
            </el-form-item>
            <el-form-item label="注释">
              <div class="tips">6期分期费率 0 ~ 4.5</div>
            </el-form-item>
          </template>
          <el-form-item label="12期">
            <el-switch v-model="model.staging12"> </el-switch>
          </el-form-item>
          <template v-if="model.staging12">
            <el-form-item label="12期商贴费率">
              <el-input-number
                :precision="2"
                :step="0.1"
                :max="7.5"
                v-model="model.proportion12"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="12期客贴费率">
              <el-input v-model="client12" disabled></el-input>
            </el-form-item>
            <el-form-item label="注释">
              <div class="tips">12期分期费率 0 ~ 7.5</div>
            </el-form-item>
          </template>
        </template>
      </template>

      <div class="footer-actions">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="default" @click="handleClose">取消</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<script>
import { getAccountList, saveMerchant, getMerchantList } from '@/api/api'
import { isEmail, isPhone } from '@/utils/validate'
import {
  getToken,
} from '@/utils/auth'
const RATE_6 = 4.5
const RATE_12 = 7.5
export default {
  methods: {
    show () {
      this.visible = true
      this.getAccountList()
      this.getMerchantList()
    },
    handleClose () {
      this.userList = []
      this.merchantList = []
      this.loadMerchant = false
      this.loading = false
      this.queryParams = {
        userName: getToken(),
        customerName: '',
        customerMobile: '',
        page: 1,
        size: 10,
      }
      this.model = { userName: '', parentId: '', customerAliPayID: '', customerWeChatID: '', isStages: true, isAlipay: true, isName: true, isMobile: true, staging6: true, staging12: true, proportion6: RATE_6, proportion12: RATE_12, }
      this.$refs.formRef.resetFields()
      this.visible = false
    },
    onMerchantScroll (e) {
      if (this.loadMerchant) { return }
      this.loadMerchant = true
      if (this.queryParams.page < this.queryParams.total) {
        this.queryParams.page++
        this.getMerchantList()
      }
    },
    onSubmit () {
      this.$refs.formRef.validate(valid => {
        if (!valid) { return }
        let BearCost = ''
        if (this.model.staging6) {
          BearCost += `6-${this.model.proportion6 === 0 ? 0 : 100}-${this.model.proportion6}`
        }
        if (this.model.staging12) {
          BearCost += `,12-${this.model.proportion12 === 0 ? 0 : 100}-${this.model.proportion12}`
        }
        const params = {
          parentId: this.model.parentId,
          customerAliPayID: this.model.customerAliPayID.trim(),
          customerMobile: this.model.customerMobile,
          customerName: this.model.customerName.trim(),
          customerNames: this.model.customerNames.trim(),
          customerWeChatID: this.model.customerWeChatID.trim(),
          mailAccount: this.model.mailAccount,
          isName: this.model.isName,
          isMobile: this.model.isMobile,
          hbfqNumBearCost: BearCost,
          userName: this.model.userName,
          profitsharingRate: this.model.profitsharingRate,
          isPayWays: `${this.model.isStages ? 1 : 0},${this.model.isAlipay ? 1 : 0}`
        }
        this.loading = true
        saveMerchant(params).then(res => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success('添加成功')
            this.$emit('ok')
            this.handleClose()
          } else {
            this.$message.error(res.msg)
          }
        }, () => {
          this.loading = true
        })
      })
    },
    onMerchantSearch (keyword) {
      this.queryParams.customerName = keyword
      this.queryParams.page = 1
      this.merchantList = []
      this.getMerchantList()
    },
    getMerchantList () {
      getMerchantList(this.queryParams).then(res => {
        this.loadMerchant = false
        if (res.code === 0) {
          // res.data = res.data.filter(item => item.AuthoStatus === '0')
          this.merchantList = this.merchantList.concat(res.data)
        }
        this.queryParams.total = Math.ceil(parseInt(res.total) / this.queryParams.page)
      }, () => {
        this.loadMerchant = false
      })
    },
    getAccountList () {
      if (getToken() !== 'admin') {
        this.userList.push(getToken())
        this.model.userName = getToken()
        return
      }
      getAccountList().then(res => {
        if (res.code === 0 && Array.isArray(res.data)) {
          this.model.userName = res.data[0]
          this.userList = res.data
        }
      })
    },
    validMobile (rule, value, callback) {
      if (!isPhone(value)) {
        return callback(new Error('手机号格式不正确'))
      }
      callback()
    },
    validAli (rule, value, callback) {
      if (isPhone(value) || isEmail(value)) {
        return callback()
      }
      callback(new Error('支付宝账号格式不正确'))
    },
    validEmail (rule, value, callback) {
      if (!isEmail(value)) {
        return callback(new Error('邮箱格式不正确'))
      }
      callback()
    },
  },
  data () {
    return {
      title: '添加商户',
      visible: false,
      model: { userName: '', parentId: '', customerAliPayID: '', customerWeChatID: '', isStages: true, isAlipay: true, isName: true, isMobile: true, staging6: true, staging12: true, proportion6: RATE_6, proportion12: RATE_12, },
      userList: [],
      merchantList: [],
      loadMerchant: false,
      loading: false,
      queryParams: {
        userName: getToken(),
        customerName: '',
        customerMobile: '',
        page: 1,
        total: 1,
        size: 10,
      },
      formRules: {
        customerName: [{ required: true, message: '请填写商户名称' }],
        customerNames: [{ required: true, message: '请填写商户拥有人名称' }],
        customerMobile: [{ required: true, message: '请填写商户编号' }],
        customerAliPayID: [{ required: true, message: '请填写支付宝账号' }, { validator: this.validAli }],
        customerWeChatID: [{ required: true, message: '请填写微信商户号' }],
        mailAccount: [{ required: true, message: '请填写邮箱' }, { validator: this.validEmail }],
      }
    }
  },
  computed: {
    client6 () {
      return (RATE_6 - this.model.proportion6).toFixed(2)
    },
    client12 () {
      return (RATE_12 - this.model.proportion12).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-actions {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 30px;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
::v-deep .el-form--inline .el-form-item {
  width: 46%;
}
// ::v-deep .el-form {
//   width: 60%;
// }
.tips {
  // font-size: 12px;
  color: #f56c6c;
}
</style>
