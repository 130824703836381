<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="close"
    size="70%"
    class="drawer"
  >
    <el-table
      v-loading="loading"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :data="tableData"
      row-key="CustomerID"
      border
      style="width: 100%"
      lazy
    >
      <el-table-column
        align="center"
        prop="CustomerName"
        label="商户名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="CustomerNames"
        label="商户拥有人名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="CreateDateTime"
        label="创建时间"
      ></el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tooltip
            v-if="pullStatus !== 1"
            class="item"
            effect="dark"
            content="编辑"
            placement="top"
          >
            <i @click="onEdit(scope.row)" class="icon el-icon-edit"></i>
          </el-tooltip>
          <el-dropdown>
            <span class="el-dropdown-link">
              <Icon iconClass="qrCode"></Icon>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'polymerization', '聚合码')
                "
                >聚合码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="onShowQRCode(scope.row, 'wechart', '微信码')"
                >微信码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'hbpay', '花呗分期二维码')
                "
                >花呗码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'aliFullPay', '支付宝码')
                "
                >支付宝码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'alipay', '支付宝+花呗码')
                "
                >支付宝+花呗码</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="
                  onShowQRCode(scope.row, 'wechartAli', '支付宝+微信码')
                "
                >支付宝+微信码</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <QRCode ref="qrCodeRef"></QRCode>
    <MerchantEditModule @ok="loadData" ref="editRef"></MerchantEditModule>
    <ProviewImg ref="proviewRef"></ProviewImg>
  </el-drawer>
</template>

<script>
import { getChildMerchantList, getQrcodeImg } from '@/api/api'

import MerchantEditModule from './MerchantEditModule.vue'
import QRCode from '@/components/qrCode'
import ProviewImg from '@/components/ProviewImg.vue'
export default {
  data () {
    return {
      title: '子商户',
      visible: false,
      loading: false,
      tableData: [],
      parentId: '',
      pullStatus: 0,
    }
  },
  methods: {
    show (options) {
      this.title = options.CustomerName
      this.parentId = options.CustomerID
      this.pullStatus = options.pullStatus
      this.visible = true
      this.loadData()
    },
    close () {
      this.visible = false
    },
    onEdit (record) {
      this.$refs.editRef.show(record.CustomerID)
    },
    onShowQRCode (e, mode, title) {
      this.loading = true
      getQrcodeImg({ customerId: e.CustomerID, mode: mode }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.$refs.proviewRef.show({
            title,
            src: res.result
          })
        } else {
          this.$message.error(res.message)
        }
      })
      // this.$refs.qrCodeRef.show({
      //   title: title,
      //   content: payBaseUrl + '/index.html?qrCodeID=' + e.CustomerID + '&mode=' + mode
      // })
    },
    loadData () {
      getChildMerchantList({ parentId: this.parentId }).then(res => {
        if (res.code === 0) {
          this.tableData = res.data
        }
        console.log('🚀 ~ file: ChildMerchant.vue ~ line 51 ~ getChildMerchantList ~ res', res)
      })
    }
  },
  components: {
    QRCode,
    MerchantEditModule,
    ProviewImg
  }
}
</script>

<style lang="scss" scoped>
.icon,
.svg-icon,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.icon,
.svg-icon {
  margin-right: 6px;
  vertical-align: middle;
}
</style>
