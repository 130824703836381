<template>
  <el-dialog
    :title="config.title"
    :visible.sync="visible"
    width="440px"
    :before-close="handleClose"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <canvas ref="canvasRef"></canvas>
  </el-dialog>
</template>

<script>
const QRCode = require('qrcode')
export default {
  methods: {
    show (options) {
      this.visible = true
      this.config = options
      console.log(options.content)
      this.$nextTick(() => {
        var canvas = this.$refs.canvasRef
        QRCode.toCanvas(canvas, options.content, { width: 400 }, function (error) {
          if (error) console.error(error)
        })
      })
      // new qrCode()
    },
    handleClose () {
      this.visible = false
      this.config = {
        title: '二维码',
        content: ''
      }
    }
  },
  data () {
    return {
      visible: false,
      config: {
        title: '二维码',
        content: ''
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
