<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose"
    size="60%"
    class="drawer"
    :append-to-body="true"
  >
    <el-form
      ref="formRef"
      :model="model"
      :rules="formRules"
      label-width="140px"
      v-loading="loading"
      inline
    >
      <el-form-item label="账号">
        <el-select v-model="model.userName">
          <el-option
            v-for="(item, key) in userList"
            :label="item"
            :value="item"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="
          model.parentId || model.parentId === '0'
            ? '子' + '商户名称'
            : '商户名称'
        "
        prop="customerName"
      >
        <el-input v-model="model.customerName"></el-input>
      </el-form-item>
      <el-form-item
        :label="
          model.parentId || model.parentId === '0'
            ? '子' + '商户拥有人名称'
            : '商户拥有人名称'
        "
        prop="customerNames"
      >
        <el-input v-model="model.customerNames"></el-input>
      </el-form-item>
      <el-form-item
        :label="
          model.parentId || model.parentId === '0'
            ? '子' + '商户编号'
            : '商户编号'
        "
        prop="customerMobile"
      >
        <el-input v-model="model.customerMobile"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId || model.parentId === '0'"
        label="支付宝账号"
        prop="customerAliPayID"
      >
        <el-input v-model="model.customerAliPayID"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId || model.parentId === '0'"
        label="微信商户号"
        prop="customerWeChatID"
      >
        <el-input v-model="model.customerWeChatID"></el-input>
      </el-form-item>
      <el-form-item
        :label="
          model.parentId || model.parentId === '0'
            ? '子' + '商户邮箱'
            : '商户邮箱'
        "
        prop="mailAccount"
      >
        <el-input v-model="model.mailAccount"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!model.parentId || model.parentId === '0'"
        label="微信分账比例"
        prop="profitsharingRate"
      >
        <el-select
          :disabled="!model.customerWeChatID"
          v-model="model.profitsharingRate"
        >
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="微信费率" prop="profitsharingRate">
        <el-select
          :disabled="!!model.customerWeChatID"
          v-model="model.wechatRate"
        >
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付宝费率" prop="profitsharingRate">
        <el-select v-model="model.alipayRate">
          <el-option
            v-for="(item, key) in 40"
            :label="(item * 0.01).toFixed(2) + '%'"
            :value="(item * 0.0001).toFixed(4)"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <template v-if="!model.parentId || model.parentId === '0'">
        <el-form-item label="付款时填写手机号">
          <el-switch v-model="model.isMobile"> </el-switch>
        </el-form-item>
        <el-form-item label="付款时填写姓名">
          <el-switch v-model="model.isName"> </el-switch>
        </el-form-item>
        <el-form-item label="花呗分期支付">
          <el-switch v-model="model.isStages"> </el-switch>
        </el-form-item>
        <el-form-item label="支付宝支付">
          <el-switch v-model="model.isAlipay"> </el-switch>
        </el-form-item>
        <template v-if="model.isStages">
          <el-form-item label="6期">
            <el-switch v-model="model.staging6"> </el-switch>
          </el-form-item>
          <template v-if="model.staging6">
            <el-form-item label="6期商贴费率">
              <el-input-number
                v-model="model.proportion6"
                :precision="2"
                :step="0.1"
                :max="4.5"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="6期客贴费率">
              <el-input v-model="client6" disabled></el-input>
            </el-form-item>
            <el-form-item label="注释">
              <div class="tips">6期分期费率 0 ~ 4.5</div>
            </el-form-item>
          </template>
          <el-form-item label="12期">
            <el-switch v-model="model.staging12"> </el-switch>
          </el-form-item>
          <template v-if="model.staging12">
            <el-form-item label="12期商贴费率">
              <el-input-number
                :precision="2"
                :step="0.1"
                :max="7.5"
                v-model="model.proportion12"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="12期客贴费率">
              <el-input v-model="client12" disabled></el-input>
            </el-form-item>
            <el-form-item label="注释">
              <div class="tips">12期分期费率 0 ~ 7.5</div>
            </el-form-item>
          </template>
        </template>
      </template>

      <div class="footer-actions">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="default" @click="handleClose">取消</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<script>
import { getAccountList, updateMerchant, getMerchantDetail } from '@/api/api'
import { isEmail, isPhone } from '@/utils/validate'
import {
  getToken,
} from '@/utils/auth'
const RATE_6 = 4.5
const RATE_12 = 7.5
export default {
  methods: {
    show (customerId) {
      this.customerId = customerId
      this.visible = true
      this.getDetail()
      this.getAccountList()
    },
    handleClose () {
      this.userList = []
      this.loading = false
      this.model = { userName: '', parentId: '', customerAliPayID: '', customerWeChatID: '', isStages: true, isAlipay: true, isName: true, isMobile: true, staging6: true, staging12: true, proportion6: RATE_6, proportion12: RATE_12, }
      this.$refs.formRef.resetFields()
      this.visible = false
    },
    onMerchantScroll (e) {
      if (this.loadMerchant) { return }
      this.loadMerchant = true
      if (this.queryParams.page < this.queryParams.total) {
        this.queryParams.page++
        this.getMerchantList()
      }
    },
    onSubmit () {
      this.$refs.formRef.validate(valid => {
        if (!valid) { return }
        let BearCost = ''
        if (this.model.staging6) {
          BearCost += `6-${this.model.proportion6 === 0 ? 0 : 100}-${this.model.proportion6}`
        }
        if (this.model.staging12) {
          BearCost += `,12-${this.model.proportion12 === 0 ? 0 : 100}-${this.model.proportion12}`
        }
        const params = {
          customerID: this.customerId,
          parentId: this.model.parentId,
          alipayID: this.model.customerAliPayID.trim(),
          profitsharingRate: this.model.profitsharingRate,
          mobile: this.model.customerMobile,
          name: this.model.customerName,
          names: this.model.customerNames,
          wechatID: this.model.customerWeChatID.trim(),
          mailAccount: this.model.mailAccount.trim(),
          isName: this.model.isName,
          isMobile: this.model.isMobile,
          hbfqNumBearCost: BearCost,
          userName: this.model.userName,
          isPayWays: `${this.model.isStages ? 1 : 0},${this.model.isAlipay ? 1 : 0}`
        }
        this.loading = true
        updateMerchant(params).then(res => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success('操作成功')
            this.$emit('ok')
            this.handleClose()
          } else {
            this.$message.error(res.msg)
          }
        }, () => {
          this.loading = true
        })
      })
    },
    getAccountList () {
      if (getToken() !== 'admin') {
        this.userList.push(getToken())
        return
      }
      getAccountList().then(res => {
        if (res.code === 0 && Array.isArray(res.data)) {
          this.userList = res.data
        }
      })
    },
    getDetail () {
      getMerchantDetail({ customerId: this.customerId }).then(res => {
        if (res.code === 0) {
          if (!res.data.isPayWays) { res.data.isStages = true; res.data.isAlipay = true } else {
            const flags = res.data.isPayWays.split(',')
            res.data.isStages = !!parseInt(flags[0]); res.data.isAlipay = !!parseInt(flags[1])
          }
          this.splitHbfqNumBearCost(res.data)
          this.model = res.data
        }
      })
    },
    splitHbfqNumBearCost (item) {
      if ('hbfqNumBearCost' in item) {
        if (!item.hbfqNumBearCost) {
          item.businessPost6 = '100'
          item.proportion6 = RATE_6
          item.client6 = 0
          item.businessPost12 = '100'
          item.proportion12 = RATE_12
          item.client12 = 0
          return
        }
        const stagings = item.hbfqNumBearCost.split(',')
        const length = stagings.length
        if (length === 1) {
          const source = stagings[0].split('-')
          source[2] = parseFloat(source[2]) ? parseFloat(source[2]) : 0
          if (source[0] === '6') {
            item.businessPost6 = source[1]
            item.proportion6 = source[2]
            item.client6 = (RATE_6 - source[2]).toFixed(2)
            item.staging6 = true

            item.businessPost12 = '100'
            item.proportion12 = 7.5
            item.client12 = 0
          } else {
            item.businessPost12 = source[1]
            item.proportion12 = source[2]
            item.client12 = (RATE_12 - source[2]).toFixed(2)
            item.staging12 = true

            item.businessPost6 = '100'
            item.proportion6 = 4.5
            item.client6 = 0
          }
        }
        if (length === 2) {
          if (stagings[0] === '') {
            item.businessPost6 = '100'
            item.proportion6 = 4.5
            item.client6 = 0
          } else {
            const source0 = stagings[0].split('-')
            source0[2] = parseFloat(source0[2]) ? parseFloat(source0[2]) : 0
            if (source0[0] === '6') {
              item.businessPost6 = source0[1]
              item.proportion6 = source0[2]
              item.client6 = (RATE_6 - source0[2]).toFixed(2)
              item.staging6 = true
            }
          }
          const source1 = stagings[1].split('-')
          source1[2] = parseFloat(source1[2]) ? parseFloat(source1[2]) : 0

          if (source1[0] === '12') {
            item.businessPost12 = source1[1]
            item.proportion12 = source1[2]
            item.client12 = (RATE_12 - source1[2]).toFixed(2)
            item.staging12 = true
          }
        }
      } else {
        item.businessPost6 = '100'
        item.proportion6 = RATE_6
        item.client6 = 0
        item.staging6 = true
        item.businessPost12 = '100'
        item.proportion12 = RATE_12
        item.client12 = 0
        item.staging12 = true
      }
    },
    validMobile (rule, value, callback) {
      if (!isPhone(value)) {
        return callback(new Error('手机号格式不正确'))
      }
      callback()
    },
    validAli (rule, value, callback) {
      if (isPhone(value) || isEmail(value)) {
        return callback()
      }
      callback(new Error('支付宝账号格式不正确'))
    },
    validEmail (rule, value, callback) {
      if (!isEmail(value)) {
        return callback(new Error('邮箱格式不正确'))
      }
      callback()
    },
  },
  data () {
    return {
      title: '修改商户',
      visible: false,
      model: { userName: '', parentId: '', customerAliPayID: '', customerWeChatID: '', isStages: true, isAlipay: true, isName: true, isMobile: true, staging6: true, staging12: true, proportion6: RATE_6, proportion12: RATE_12, },
      userList: [],
      loadMerchant: false,
      loading: false,
      customerId: '',
      formRules: {
        customerName: [{ required: true, message: '请填写商户名称' }],
        customerNames: [{ required: true, message: '请填写商户拥有人名称' }],
        customerMobile: [{ required: true, message: '请填写商户编号' }],
        customerAliPayID: [{ required: true, message: '请填写支付宝账号' }, { validator: this.validAli }],
        customerWeChatID: [{ required: true, message: '请填写微信商户号' }],
        mailAccount: [{ required: true, message: '请填写邮箱' }, { validator: this.validEmail }],
      }
    }
  },
  computed: {
    client6 () {
      return (RATE_6 - this.model.proportion6).toFixed(2)
    },
    client12 () {
      return (RATE_12 - this.model.proportion12).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding: 0 20px;
}
// ::v-deep .el-form {
//   width: 60%;
// }
::v-deep .el-form--inline .el-form-item {
  width: 46%;
}
.footer-actions {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 30px;
}
.tips {
  // font-size: 12px;
  color: #f56c6c;
}
</style>
