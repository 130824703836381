/**
 * 邮箱
 * @param {*} value
 * @returns
 */
export const isEmail = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
}

/**
 * 手机号
 * @param {*} value
 * @returns
 */
export const isPhone = (value) => {
  return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(value)
}

/**
 * 微信
 * @param {*} value
 * @returns
 */
export const isWechat = (value) => {
  return /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/.test(value)
}
