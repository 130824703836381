<template>
  <el-dialog
    :title="config.title"
    :visible.sync="visible"
    width="440px"
    :before-close="handleClose"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <div class="image">
      <el-image :src="config.src"></el-image>
    </div>
  </el-dialog>
</template>

<script>
export default {
  methods: {
    show (options) {
      this.visible = true
      this.config = options
    },
    handleClose () {
      this.visible = false
      this.config = {
        title: '二维码',
        content: ''
      }
    }
  },
  data () {
    return {
      visible: false,
      config: {
        title: '二维码',
        content: '',
        src: '',
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.image {
  border: 1px solid #f2f4f6;
}
</style>
